import { ref, reactive, computed } from 'vue'

export default () => {
    // ************************************************
    // ****************  properties ****************
    // ***********************************************
    const driver = ref('')
    const language = ref('')

    const initialSensorProperties = {
        uuid: '',
        name: '',
        firmware: '',
        firmwareNRF: '',
        cumulativeTime: '',
        memory: '',
        turnOffTime: '',
        gpsDate: '',
        // gpsFix == 0 => 'No fix'
        // gpsFix == 1 => 'Dead Reckoning Only'
        // gpsFix == 2 => '2D Fix'
        // gpsFix == 3 => '3D Fix'
        // gpsFix == 4 => 'Combined GPS and Dead Reckoning'
        // gpsFix == 5 => 'Time Fix Only'
        // gpsFixStr => 'Reserved'
        gpsFix: '',
        // gpsFlags & 0b00000001 => 'GPS fix OK'
        // gpsFlags & 0b00000010 => 'Differential solution (DGPS used)'
        // gpsFlags & 0b00000100 => 'Week number set (Valid GPS WN)'
        // gpsFlags & 0b00001000 => 'Time of week set (Valid GPS iTOW)'
        gpsFlags: '',
        gpsSatellites: '',
        gpsAccuracy: '', // (cm)
        gpsDistance: '', // (m)
        gpsSpeed: '', // (cm/s)  x3600 (60x60) to hours // :100:1000
        // https://docs.google.com/spreadsheets/d/108dckBNfHDLugHRFsgxGZhFY4ov4weqGdiVBcqHOd_8/edit#gid=820861251
        fusionStatus: '',
        // stmMode == 0xe3 => 'Navigation mode (is not writing of flash)'
        // stmMode == 0xe4 => 'Navigation mode (is writing of flash)'
        // stmMode == 0xb7 => 'Memory mode'
        // stmMode == 0x25 => 'Calibration mode'
        stmMode: '',
        battery: '',
        filesCount: '',
        assStatus: '',
        ausStatus: '',
        amsMode: '',
    }
    const properties = reactive({ ...initialSensorProperties })
    const nearbyDevices = reactive([])
    const files = reactive([])
    const devices = reactive([])
    const fileSizeLimit = 30000 // +- 5s

    /**
     * Check whether a sensor is connected
     */
    const isBusy = ref(false)
    /**
     * Check whether the channel communication is enable
     */
    const isEnabled = ref(false)
    /**
     * Check whether the Web API is available
     */
    const hasWebAPIAvailble = ref(false)
    /**
     * Check whether a sensor is connected
     */
    const isConnected = ref(false)

    /**
     * Check whether a there is sensor nearby
     */
    const isNearby = ref(false)

    const signalForce = computed(() => {
        // fusionStatus => 0 gpsFix => 0 => 1
        // fusionStatus => 0 gpsFix => 3 => 1
        // fusionStatus => 2 gpsFix => 3 => 2
        // fusionStatus => 0 gpsFix => 2 => 1
        // fusionStatus => 0 gpsFix => 3 => 1
        // fusionStatus => 2 gpsFix => 3 => 2
        // fusionStatus => 34 gpsFix => 3 => 3
        if ((properties.fusionStatus === 32 || properties.fusionStatus === 34) && properties.gpsFix === 3) {
            return 3 // #3cb44b // MAP OK + DISTANCE OK + SPEED OK
        } else if (properties.fusionStatus === 2 && properties.gpsFix === 3) {
            return 2 //#FC3 // MAP OK + DISTANCE OK + SPEED OK
        } else {
            return 1 //#e6194b // MAP HIDDEN + DISTANCE HIDDEN + SPEED HIDDEN
        }
    })

    const isPaused = computed(() => properties.assStatus === 2)
    const isRecording = computed(() => properties.assStatus === 1)
    const isStopped = computed(() => properties.assStatus === 0)

    const isMemoryMode = computed(() => properties.amsMode === 6)

    const hasGpsSignal = computed(() => signalForce.value === 3 || signalForce.value === 2)

    const initialSensorLiveSession = {
        discipline: undefined,
        horseId: undefined,
        riderId: undefined,
        goal: null,
        goal_value: null,
        /**
         * Stores the time when the live
         * session started.
         */
        startedAt: undefined,

        /**
         * Stores when the live session
         * has ended at.
         */
        endedAt: undefined,

        /**
         * Stores if the live session is
         * currently paused.
         */
        paused: false,

        /**
         * Stores the pauses that has been
         * performed during the live session.
         *
         * Format: { from: null, to: null }
         */
        pauses: [],

        /**
         * Stores the current time. This is updated
         * every second. This must exist due the fact
         * that the computer properties need to know when
         * the data changes, and now it's always in motion.
         *
         * For reference:
         * https://github.com/vuejs/Discussion/issues/214#issuecomment-116122788
         */
        now: { time: undefined, handler: undefined },

        /**
         * Heartrate values stream
         *
         * Format: [{'60':5}...]
         */
        heartrateStream: [],
        /**
         * ECG values stream
         *
         * Format: [{60:[5,10,2,...]}...]
         */
        ecgStream: [],
        usePolar: false,
    }
    const liveSession = reactive({ ...initialSensorLiveSession })

    // return live distance in meters
    const liveDistance = computed(() => {
        if (hasGpsSignal.value && properties.gpsDistance) {
            return properties.gpsDistance.toFixed(2)
        }
        return 0
    })

    // return live speed in m/min
    const liveSpeed = computed(() => {
        if (hasGpsSignal.value && properties.gpsSpeed) {
            return (properties.gpsSpeed * 0.6).toFixed(2)
        }
        return 0
    })

    /**
     * Determines the duration of the current live session in seconds.
     */
    const liveDuration = computed(() => {
        if (!liveSession.startedAt) return 0
        const totalSeconds = liveSession.endedAt
            ? liveSession.endedAt - liveSession.startedAt
            : liveSession.now.time - liveSession.startedAt

        return parseInt((totalSeconds - pausesInSec()) / 1000)
    })

    const liveSessionGoalPercentage = computed(() => {
        try {
            if (!liveSession.startedAt) return 0
            const goal = liveSession.goal
            let percent = 0
            if (goal === null || goal === undefined || goal === 'open_goal') return percent
            switch (goal) {
                case 'distance':
                    if (hasGpsSignal.value) {
                        percent = (liveDistance.value * 100) / liveSession.goal_value
                    } else {
                        percent = 0
                    }
                    break
                case 'duration':
                    percent = (liveDuration.value * 100) / liveSession.goal_value

                    break
                default:
                    break
            }
            return Math.floor(percent)
        } catch (err) {
            console.log('liveSessionGoalPercentage', err)
        }
    })

    const liveSessionGoalCompleted = computed(() => {
        try {
            if (!liveSession.startedAt) return false
            const goal = liveSession.goal
            if (goal === null || goal === undefined || goal === 'open_goal') return false
            return liveSessionGoalPercentage.value >= 100
        } catch (err) {
            console.log('liveSessionGoalCompleted', err)
        }
    })

    const pausesInSec = () => {
        return liveSession.pauses.reduce((acc, { from, to }) => {
            // We could still be in pause mode...
            const pauseTo = to ?? liveSession.now
            return acc + (pauseTo - from ?? 0)
        }, 0)
    }

    // ************************************************
    // **************** methods ****************
    // ***********************************************
    const resetLiveSession = () => {
        Object.assign(liveSession, initialSensorLiveSession)
    }
    const resetSensorProperties = () => {
        Object.assign(properties, initialSensorProperties)
    }
    const resetSensorFiles = () => {
        files.splice(0) //to not break the reactivity
        properties.filesCount = 0
    }
    const resetSensor = () => {
        resetSensorProperties()
        resetSensorFiles()
    }

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    const startLiveSessionHandler = () => {
        // liveSession.now.handler = setInterval(() => {
        //     liveSession.now.time = Date.now()
        // }, 100)

        //every seconds
        liveSession.now.handler = setInterval(() => {
            liveSession.now.time = Date.now()
        }, 1000)
    }

    const resumeLiveSession = async () => {
        liveSession.paused = false
        liveSession.pauses[liveSession.pauses.length - 1].to = Date.now()
        startLiveSessionHandler()
    }

    const pauseLiveSession = async () => {
        liveSession.paused = true
        liveSession.pauses.push({
            from: Date.now(),
            to: undefined,
        })
        clearInterval(liveSession.now.handler)
        liveSession.now.time = undefined
    }

    const startLiveSession = (usePolar = false) => {
        liveSession.usePolar = usePolar
        //fix reset heartrate stream at start
        liveSession.heartrateStream = []
        liveSession.startedAt = Date.now()
        liveSession.now.time = Date.now()
        startLiveSessionHandler()
    }

    const stopLiveSession = async () => {
        liveSession.endedAt = Date.now()
        // Clear the interval for the now update.
        clearInterval(liveSession.now.handler)
        // Reset the now timestamps.
        liveSession.now.time = undefined
        liveSession.now.handler = undefined
    }

    const saveLiveSessionToLatestFile = async () => {
        if (!liveSession.startedAt || !liveSession.endedAt) {
            // console.log('!liveSession.startedAt || !liveSession.endedAt', !liveSession.startedAt, !liveSession.endedAt)
            return resetLiveSession()
        }
        await sleep(200)
        let latestIndex = 0 // by default set the first one as latest
        //we will find the highest index in order to determine who is the latest file recorded
        for (let i = 1; i < files.length; i++) {
            if (files[i].properties.index > files[latestIndex].properties.index) {
                latestIndex = i
            }
        }
        let meta = {
            discipline: liveSession.discipline,
            horseId: liveSession.horseId,
            riderId: liveSession.riderId,
            goal: liveSession.goal,
            goal_value: liveSession.goal_value,
        }
        console.log('saveLiveSessionToLatestFile', files.length, latestIndex, meta)
        if (files.length > 0) {
            if (files[latestIndex].properties.weekNumber === 0 && liveSession.endedAt) {
                meta.date = liveSession.endedAt
            }
            try {
                await files[latestIndex].save(meta, { heartrateStream: liveSession.heartrateStream }) //save into the memory
            } catch (err) {
                console.log('saveLiveSessionToLatestFile failed save hr file', err)
            }
        }
        resetLiveSession()
    }

    // ************************************************
    // **************** to override methods ****************
    // ***********************************************
    /**
     * Open a connection to a sensor
     */
    const connect = async () => {
        throw new Error('Not implemented connect')
    }
    /**
     * Close a connection to a sensor
     */
    const disconnect = async (forgetDevice = false) => {
        throw new Error('Not implemented disconnect')
    }
    /**
     * Read the uuid
     */
    const getUUID = async () => {
        throw new Error('Not implemented getUUID')
    }
    /**
     * Set to power on mode
     */
    const powerOn = async () => {
        throw new Error('Not implemented powerOn')
    }
    /**
     * Shutdown the sensor
     */
    const shutdown = async () => {
        throw new Error('Not implemented shutdown')
    }
    /**
     * Set to OTA mode / DFU
     */
    const setOTAMode = async () => {
        throw new Error('Not implemented setOTAMode')
    }
    /**
     * Set to init mode
     */
    const setInitMode = async () => {
        throw new Error('Not implemented setInitMode')
    }
    /**
     * Set to init mode
     */
    const setADVMode = async () => {
        throw new Error('Not implemented setADVMode')
    }
    /**
     * Set to navigation mode
     */
    const setNavigationMode = async () => {
        throw new Error('Not implemented setNavigationMode')
    }
    /**
     * Set to memory mode
     */
    const setMemoryMode = async () => {
        throw new Error('Not implemented setMemoryMode')
    }
    /**
     * Set to calibration mode
     */
    const setCalibrationMode = async () => {
        throw new Error('Not implemented setCalibrationMode')
    }
    /**
     * Set to lost mode
     */
    const setLostMode = async () => {
        throw new Error('Not implemented setLostMode')
    }
    /**
     * Reset sensor settings
     */
    const resetSettings = async () => {
        throw new Error('Not implemented resetSettings')
    }
    /**
     * Set to STMBL mode
     */
    const setSTMBLMode = async () => {
        throw new Error('Not implemented setSTMBLMode')
    }
    /**
     * Set to STMNBL mode
     */
    const setSTMNBLMode = async () => {
        throw new Error('Not implemented setSTMNBLMode')
    }
    /**
     * Set to updateFirmware
     */
    const updateFirmware = async () => {
        throw new Error('Not implemented updateFirmware')
    }
    /**
     * Set to updateFirmwareNRF
     */
    const updateFirmwareNRF = async () => {
        throw new Error('Not implemented updateFirmwareNRF')
    }
    /**
     * Get the current mode
     */
    const getMode = async () => {
        throw new Error('Not implemented getMode')
    }
    /**
     * Remove the sensor memory
     */
    const eraseMemory = async () => {
        throw new Error('Not implemented eraseMemory')
    }
    /**
     * IDLE
     */
    const idle = async () => {
        throw new Error('Not implemented idle')
    }
    /**
     * RECORD
     */
    const record = async () => {
        throw new Error('Not implemented record')
    }
    /**
     * PAUSE
     */
    const pause = async () => {
        throw new Error('Not implemented pause')
    }
    /**
     * RESUME
     */
    const resume = async () => {
        throw new Error('Not implemented resume')
    }
    /**
     * STOP
     */
    const stop = async () => {
        throw new Error('Not implemented stop')
    }
    /**
     * GET_LIST
     */
    const getSessionsList = async () => {
        throw new Error('Not implemented getSessionsList')
    }
    /**
     * GET_LIGHT_SESSION
     */
    const syncSelectedSessions = async () => {
        throw new Error('Not implemented syncSelectedSessions')
    }
    /**
     * ASK PERMISSIONS
     */
    const askPermissions = async () => {
        throw new Error('Not implemented askPermissions')
    }

    return {
        driver,
        language,
        nearbyDevices,
        liveSession,
        properties,
        files,
        fileSizeLimit,
        resetLiveSession,
        resetSensorProperties,
        resetSensorFiles,
        resetSensor,
        signalForce,
        sleep,
        resumeLiveSession,
        pauseLiveSession,
        startLiveSession,
        stopLiveSession,
        saveLiveSessionToLatestFile,
        isBusy,
        isEnabled,
        hasWebAPIAvailble,
        isConnected,
        isNearby,
        isPaused,
        isRecording,
        isStopped,
        isMemoryMode,
        hasGpsSignal,
        liveDistance,
        liveSpeed,
        liveDuration,
        liveSessionGoalPercentage,
        liveSessionGoalCompleted,
        pausesInSec,
        connect,
        disconnect,
        getUUID,
        powerOn,
        shutdown,
        setOTAMode,
        setInitMode,
        setADVMode,
        setNavigationMode,
        setMemoryMode,
        setCalibrationMode,
        resetSettings,
        setLostMode,
        setSTMBLMode,
        setSTMNBLMode,
        updateFirmware,
        updateFirmwareNRF,
        getMode,
        eraseMemory,
        idle,
        record,
        pause,
        resume,
        getSessionsList,
        syncSelectedSessions,
        askPermissions,
    }
}
