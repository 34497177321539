import { ref, computed, watch } from 'vue'
import useSensor from './sensor'
import useFile from './file'
import { KeepAwake } from '@capacitor-community/keep-awake'

import {
    getReadFileCommand,
    decodeRecord,
    decodeUUID,
    listOfAvailableCommands,
    splitMessage,
    encodeCommand,
    byteArrayToLong,
    HEADER_BYTES,
    concatenateUint8Arrays,
} from './common'

export default ({ triggerEvent, language }) => {
    const sensor = useSensor()

    // ************************************************
    // ****************  CUSTOM properties ****************
    // ***********************************************
    let serial = undefined
    const reader = ref(undefined)
    const writer = ref(undefined)
    const port = ref(null)
    const closedPromise = ref(undefined)
    const keepReading = ref(true)
    // ************************************************
    // **************** OVERRIDED properties ****************
    // ***********************************************
    sensor.language = language
    sensor.driver = 'serial'
    sensor.isEnabled = true
    // ************************************************
    // **************** CUSTOM constructor ****************
    // ***********************************************
    if ('serial' in navigator) {
        serial = navigator.serial
    }
    sensor.hasWebAPIAvailble = serial !== undefined ? true : false

    // ************************************************
    // **************** OVERRIDED methods ****************
    // ***********************************************
    /**
     * Open a connection to a sensor
     */
    sensor.connect = async () => {
        return new Promise((resolve, _) => {
            // Filter on devices
            const filters = [{ usbVendorId: 1155 }]
            // Prompt user to select any serial port.
            serial
                .requestPort({ filters })
                .then(async (portSelected) => {
                    sensor.isBusy.value = true
                    sensor.isConnected.value = true
                    port.value = portSelected
                    // Connect to `port` or add it to the list of available ports.
                    const { usbProductId, usbVendorId } = port.value.getInfo()
                    // console.log(usbProductId, usbVendorId)
                    // Wait for the serial port to open.
                    console.log('a', portSelected, serial)
                    await port.value.open({
                        baudRate: 115200,
                        // baudRate: 4294967295,
                        // baudRate: 1000000,
                        // 4,294,967,295
                        dataBits: 8,
                        stopBits: 1,
                        // parity: 'none',
                        parity: 'none',
                        // bufferSize: 64,
                        // bufferSize: bufferSize,
                        // bufferSize: 16777216,
                        bufferSize: 2097152,
                        flowControl: 'none',
                        // flowControl: 'hardware',
                    })
                    console.log('b')
                    // Turn off Serial Break signal.
                    // await port.value.setSignals({ break: true })
                    // // Turn on Data Terminal Ready (DTR) signal.
                    // await port.value.setSignals({ dataTerminalReady: true })
                    // // Turn off Request To Send (RTS) signal.
                    // await port.value.setSignals({ requestToSend: true })

                    console.log('opened')
                    const signals = await port.value.getSignals()
                    console.log(`Clear To Send:       ${signals.clearToSend}`)
                    console.log(`Data Carrier Detect: ${signals.dataCarrierDetect}`)
                    console.log(`Data Set Ready:      ${signals.dataSetReady}`)
                    console.log(`Ring Indicator:      ${signals.ringIndicator}`)
                    // reader.value = port.value.readable.getReader()
                    console.log('c')
                    //! should de done before readUntilClosed
                    keepReading.value = true
                    closedPromise.value = readUntilClosed(HEADER_BYTES)
                    sensor.properties.name = 'Alogo over USB'
                    await sensor.setNavigationMode(true)
                    await sensor.sleep(500)
                })
                .catch(async (error) => {
                    // The user didn't select a port.
                    console.log('connectSensor', error)
                    await sensor.disconnect()
                })
                .finally(async () => {
                    sensor.isBusy.value = false
                    await sensor.getUUID(true) //should be done after the isBusy false
                    await sensor.sleep(1000)
                    await sensor.getSessionsList(true)
                    await sensor.sleep(500)
                    resolve(sensor.isConnected.value)
                })
        })
    }
    /**
     * Close a connection to a sensor
     */
    sensor.disconnect = async (forgetDevice = false) => {
        try {
            sensor.isBusy.value = true
            keepReading.value = false
            sensor.resetSensor()
            if (reader.value) {
                reader.value
                    .cancel()
                    .then(async () => {
                        await closedPromise.value
                    })
                    .catch((error) => {
                        console.log('disconnect reader serial error', error)
                    })
                // Allow the serial port to be closed later.
                reader.value.releaseLock()
            }
            writer.value && writer.value.releaseLock()

            await port.value.close()
        } catch (error) {
            console.log('disconnect serial error', error)
        } finally {
            sensor.isConnected.value = false
            keepReading.value = false
            port.value = undefined
            sensor.isBusy.value = false
        }
    }
    /**
     * Read the uuid
     */
    sensor.getUUID = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'GET_UNIQUE_ID')
        await writeToSensor(command, force, 100)
    }
    /**
     * Set to power on mode
     */
    sensor.powerOn = async () => {
        throw new Error('Not implemented powerOn')
    }
    /**
     * Shutdown the sensor
     */
    sensor.shutdown = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'SET_MODE_OFF')
        await writeToSensor(command, force, 100)
    }
    /**
     * Set to OTA mode / DFU
     */
    sensor.setOTAMode = async () => {
        throw new Error('Not implemented setOTAMode')
    }
    /**
     * Set to init mode
     */
    sensor.setInitMode = async () => {
        throw new Error('Not implemented setInitMode')
    }
    /**
     * Set to init mode
     */
    sensor.setADVMode = async () => {
        throw new Error('Not implemented setADVMode')
    }
    /**
     * Set to navigation mode
     */
    sensor.setNavigationMode = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'SET_MODE_NAVIGATION')
        await writeToSensor(command, force, 100)
    }

    sensor.broadcast = async (command) => {
        // const command = listOfAvailableCommands.find(({ name }) => name === 'BROADCAST')
        console.log('command', command)
        await writeToSensor(command, force, 100)
    }

    /**
     * Set to memory mode
     */
    sensor.setMemoryMode = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'SET_MODE_MEMORY')
        await writeToSensor(command, force, 100)
    }
    /**
     * Set to calibration mode
     */
    sensor.setCalibrationMode = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'SET_MODE_CALIBRATION')
        await writeToSensor(command, force, 100)
    }
    /**
     * Reset sensor settings
     */
    sensor.resetSettings = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'RESET_SENSOR')
        await writeToSensor(command, force, 100)
    }
    /**
     * Set to lost mode
     */
    sensor.setLostMode = async () => {
        throw new Error('Not implemented setLostMode')
    }
    /**
     * Set to STMBL mode
     */
    sensor.setSTMBLMode = async () => {
        throw new Error('Not implemented setSTMBLMode')
    }
    /**
     * Set to STMNBL mode
     */
    sensor.setSTMNBLMode = async () => {
        throw new Error('Not implemented setSTMNBLMode')
    }
    /**
     * Set to updateFirmware
     */
    sensor.updateFirmware = async () => {
        throw new Error('Not implemented updateFirmware')
    }
    /**
     * Set to updateFirmwareNRF
     */
    sensor.updateFirmwareNRF = async () => {
        throw new Error('Not implemented updateFirmwareNRF')
    }
    /**
     * Get the current mode
     */
    sensor.getMode = async () => {
        throw new Error('Not implemented getMode')
    }
    /**
     * Remove the sensor memory
     */
    sensor.eraseMemory = async (force = false) => {
        await sensor.setMemoryMode()
        await sensor.sleep(200)
        const command = listOfAvailableCommands.find(({ name }) => name === 'ERASE_MEMORY')
        await writeToSensor(command, force, 100)
        await sensor.sleep(200)
        await sensor.setNavigationMode(force)
        sensor.resetSensorFiles()
    }
    /**
     * IDLE
     */
    sensor.idle = async () => {
        throw new Error('Not implemented idle')
    }
    /**
     * RECORD
     */
    sensor.record = async () => {
        throw new Error('Not implemented record')
    }
    /**
     * PAUSE
     */
    sensor.pause = async () => {
        throw new Error('Not implemented pause')
    }
    /**
     * RESUME
     */
    sensor.resume = async () => {
        throw new Error('Not implemented resume')
    }
    /**
     * STOP
     */
    sensor.stop = async () => {
        throw new Error('Not implemented stop')
    }
    /**
     * GET_LIST
     */
    sensor.getSessionsList = async (force = false) => {
        const command = listOfAvailableCommands.find(({ name }) => name === 'GET_SESSION_LIST')
        await writeToSensor(command, force, 100)
    }
    /**
     * GET_LIGHT_SESSION
     */
    sensor.syncSelectedSessions = async () => {
        await sensor.setMemoryMode()
        await sensor.sleep(200)
        //trigger a fake request for reading session
        await writeFakePage()
    }

    sensor.askPermissions = async () => {}

    // ************************************************
    // **************** CUSTOM methods ****************
    // ***********************************************

    if (serial) {
        serial.onconnect = (e) => {
            console.log('connect', e, e.port || e.target)
            sensor.isNearby.value = true
            // sensor.nearbyDevices.splice(0) //to not break the reactivity
            // sensor.nearbyDevices.push(e)
            // ici on pourrait gérer le cas d'un capteur déjà connu et reconnecté afin de faire un autoconnect
            // Connect to `e.target` or add it to a list of available ports.
        }

        serial.ondisconnect = (e) => {
            console.log('disconnect', e)
            sensor.isNearby.value = false
            // sensor.nearbyDevices.splice(0) //to not break the reactivity
            sensor.disconnect()
            // Remove `e.target` from the list of available ports.
        }
    }
    const messageReader = async (message = new Uint8Array([]), timeout = 10000) => {
        if (message.length >= 8) {
            //maybe there is not more message who will be receive here so the latest frame was in the previous one
            //where 8 is the minimum size for a message
            const frame = splitMessage(message)
            if (frame.isValid) {
                // console.log('Received:', message)
                return frame
            }
        }
        //timeout for each chunk of message
        const timer = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout, { done: true, failed: true })
        })
        const { value, done, failed } = await Promise.race([reader.value.read(), timer])
        if (done) {
            if (!failed) {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! CHAMPAGNE', done, value, message)
            } else {
                // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TIMEOUT', done, value, message)
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TIMEOUT', done, value?.length, message?.length)
            }
        }
        // value is a Uint8Array.
        // console.log('chunk', value)
        if (value) {
            // console.log('chunk', value.length)
            message = concatenateUint8Arrays(message, value)
        }
        const frame = splitMessage(message)
        if (!frame.isValid && !done) {
            return messageReader(message, timeout)
        }
        // console.log('Received:', message)
        return frame
    }

    const readUntilClosed = async (minimumLength = 8) => {
        while (port.value.readable && keepReading.value) {
            let buffer = []
            try {
                reader.value = port.value.readable.getReader()
                while (true) {
                    const { value, done } = await reader.value.read()
                    if (done) {
                        // |reader| has been canceled.
                        console.log('|reader| has been canceled.')
                        break
                    }
                    if (value) {
                        console.log('value', value)
                        buffer = concatenateUint8Arrays(buffer, value)
                    }
                    // Do something with |value|...
                    if (buffer.length >= minimumLength) {
                        await decodeChunk(buffer)
                        buffer = []
                    }
                }
            } catch (error) {
                // Handle |error|...
                console.log('readUntilClosed', error)
            } finally {
                reader.value.releaseLock()
            }
        }
    }

    const writeFakePage = async (force = false) => {
        const command = getReadFileCommand({ startPage: 1, numberOfPages: 1 })
        await writeToSensor(command, force)
    }

    const readFakePage = async (message = new Uint8Array([]), timeout = 5000) => {
        const emptyPage = await messageReader(message, timeout)
        if (byteArrayToLong(emptyPage.payloadArray.slice(0, 4)) !== 1) {
            console.log('This is not the fake frame')
            return false
        }
        const fakeFrame = await messageReader(emptyPage.nextMessage, timeout)
        if (!fakeFrame.isValid) {
            console.log('Cannot verify the fake frame')
            return false
        }
        return true
    }

    const decodeChunk = async (firstChunk) => {
        if (sensor.isBusy.value) {
            triggerEvent('onProcessLocked')
            return
        }
        sensor.isBusy.value = true
        try {
            const headerString = JSON.stringify(firstChunk.slice(0, 4))
            //apply the proper processes for ONLY valid frame
            switch (headerString) {
                case '{"0":250,"1":112,"2":200,"3":137}': //MEMORY ERASED
                    console.log('MEMORY ERASED')
                    await messageReader(firstChunk, 1000)
                    //trigger a fake request for reading sessions list
                    await sensor.getSessionsList(true)
                    await sensor.sleep(500)
                    await sensor.setNavigationMode(true)
                    break
                case '{"0":250,"1":112,"2":85,"3":15}': //UUID
                    console.log('UUID')
                    const uuidFrame = await messageReader(firstChunk, 1000)
                    const res = decodeUUID(uuidFrame.payloadArray)
                    sensor.properties.uuid = res[0]
                    sensor.properties.firmware = res[1]
                    break

                case '{"0":250,"1":112,"2":200,"3":8}': //SESSIONS LIST
                    console.log('SESSION LIST')
                    sensor.resetSensorFiles() //reset the array of files

                    const sessionListFrame = await messageReader(firstChunk, 1000)
                    if (!sessionListFrame.isValid) {
                        triggerEvent('onGetSessionsListFailed')
                        break
                    }

                    sensor.properties.filesCount = byteArrayToLong(sessionListFrame.payloadArray)
                    let filesRemaining = sensor.properties.filesCount

                    let message = sessionListFrame.nextMessage
                    while (filesRemaining > 0) {
                        const frame = await messageReader(message, 1000)
                        message = frame.nextMessage
                        const record = decodeRecord(new DataView(new Uint8Array(frame.payloadArray).buffer))
                        if (record.index) {
                            const file = await useFile({
                                ...record,
                                uuid: sensor.properties.uuid,
                                language: sensor.language.value,
                            })
                            if (file.properties.size > sensor.fileSizeLimit) {
                                sensor.files.push(file)
                            } else {
                                sensor.properties.filesCount--
                                console.log(
                                    'Skipped file too small, https://github.com/Alogo-Analysis/mobile-app/issues/454'
                                )
                            }
                            filesRemaining--
                        } else {
                            triggerEvent('onGetSessionsListFailed')
                            sensor.properties.filesCount = 0
                            filesRemaining = 0
                            sensor.resetSensorFiles()
                        }
                    }
                    break

                case '{"0":250,"1":112,"2":200,"3":152}': //READ SESSION
                    console.log('READ SESSION')
                    //ignore the fake request used to go here
                    if (!(await readFakePage(firstChunk))) {
                        triggerEvent('onGetSessionFailed')
                        break
                    }

                    await KeepAwake.keepAwake()

                    const maxAttempts = 6
                    const numberMaxOfPage = 50
                    for (let fileIndex = 0; fileIndex < sensor.files.length; fileIndex++) {
                        if (!sensor.files[fileIndex].properties?.selected === true) {
                            continue
                        }
                        const startTime = Date.now()
                        if (!sensor.files[fileIndex].pagesSynced) {
                            sensor.files[fileIndex].pagesSynced = {}
                        }
                        let numberOfPages = sensor.files[fileIndex].properties.numberOfPages
                        const lastPage =
                            sensor.files[fileIndex].properties.startPage +
                            sensor.files[fileIndex].properties.numberOfPages -
                            1
                        console.log(`Start reading ${numberOfPages} pages`)
                        let currentPage = sensor.files[fileIndex].properties.startPage
                        while (currentPage < lastPage) {
                            if (sensor.files[fileIndex].pagesSynced[currentPage]) {
                                currentPage++
                                continue
                            }
                            let failed = 0

                            do {
                                const pageAsked =
                                    lastPage - currentPage < numberMaxOfPage ? lastPage - currentPage : numberMaxOfPage
                                const command = getReadFileCommand({
                                    startPage: currentPage,
                                    numberOfPages: pageAsked,
                                })
                                //to be sure to exec both in same time
                                // const delay = 200
                                await writeToSensor(command, true, 100)
                                await sensor.sleep(200)
                                let success = false
                                let buffer = new Uint8Array([])
                                for (let pageAskedIndex = 1; pageAskedIndex <= pageAsked; pageAskedIndex++) {
                                    const page = await messageReader(buffer, 200)
                                    if (!page.isValid) {
                                        console.log('page not valid')
                                        break
                                    }
                                    const pageNumber = byteArrayToLong(page.payloadArray.slice(0, 4))
                                    // console.log(`Page ${pageNumber} readed`)
                                    if (!(pageNumber === currentPage)) {
                                        console.log(`Page received ${pageNumber} readed but asked ${currentPage}`)
                                        break
                                    }
                                    //store the result
                                    sensor.files[fileIndex].pagesSynced[currentPage] = page.payloadArray.slice(
                                        4,
                                        page.payloadArray.length
                                    )

                                    currentPage++

                                    sensor.files[fileIndex].properties.syncedPercent = Math.round(
                                        ((numberOfPages - (lastPage - currentPage)) * 100) / numberOfPages
                                    )

                                    if (pageAsked === pageAskedIndex) {
                                        const eof = await messageReader(page.nextMessage, 1000)
                                        // 250, 112, 200, 154, 0, 0, 98, 1
                                        if (
                                            eof.isValid &&
                                            JSON.stringify(eof.headersArray) ===
                                                '{"0":250,"1":112,"2":200,"3":154,"4":0,"5":0}'
                                        ) {
                                            console.log(
                                                `End Of File`,
                                                sensor.files[fileIndex].properties.syncedPercent,
                                                currentPage,
                                                lastPage
                                            )
                                            success = true
                                            break // stop the loop to go to the next page
                                        } else {
                                            console.log('Failed to validate the EOF message')
                                            triggerEvent('onGetSessionFailed')
                                        }
                                    } else {
                                        buffer = page.nextMessage
                                    }
                                }
                                //should stay on that page if not succeeded
                                if (!success) {
                                    let uuidAttempt = 0
                                    const uuidAttemptMax = 3
                                    do {
                                        await sensor.getUUID(true)
                                        await sensor.sleep(200)
                                        await sensor.getUUID(true)
                                        const uuidFrame = await messageReader(new Uint8Array([]), 500)
                                        if (uuidFrame.isValid) {
                                            console.log('#### CA FONCTIONNE')
                                            break
                                        } else {
                                            console.log('#### OMG')
                                        }
                                        uuidAttempt++
                                    } while (uuidAttempt < uuidAttemptMax)
                                    if (uuidAttempt === uuidAttemptMax) {
                                        failed++
                                    }
                                } else {
                                    break
                                }
                            } while (failed <= maxAttempts)
                            if (failed > maxAttempts) {
                                console.log(`too many failed to retrieve page ${currentPage}`)
                                triggerEvent('onGetSessionFailed')
                                break
                            }
                        }
                        if (lastPage === currentPage) {
                            console.log('Sync executed in', Math.round((Date.now() - startTime) / 1000) + 's')
                            try {
                                await sensor.files[fileIndex].upload()
                            } catch (err) {
                                triggerEvent('onUploadFailed', sensor.files[fileIndex])
                                console.log('upload from serial error', err)
                            }
                        }
                    }
                    await sensor.setNavigationMode(true)
                    await KeepAwake.allowSleep()
                    break

                default:
                    console.log('SKIPPED', firstChunk)
            }
            return
        } catch (error) {
            console.log('decodeChunk', error)
        } finally {
            sensor.isBusy.value = false
            // console.log('decodeChunk FINISHED')
            return
        }
    }

    const writeToSensor = async (commandSelected, force = false, delay = 100) => {
        if (!port.value || !port.value.writable) return
        const write = new Promise(async (resolve, reject) => {
            try {
                if (sensor.isBusy.value && !force) {
                    triggerEvent('onProcessLocked')
                    return
                }
                // console.log(commandSelected)
                const data = encodeCommand(commandSelected)
                writer.value = port.value.writable.getWriter()
                await sensor.sleep(delay)
                await writer.value.write(data)
                // Allow the serial port to be closed later.
                writer.value.releaseLock()
            } catch (error) {
                console.log('writeToSensor', error)
            } finally {
                resolve(true)
            }
        })
        const timer = new Promise((resolve, reject) => {
            setTimeout(resolve, 5000, {})
        })

        await Promise.race([write, timer])
    }

    return { ...sensor }
}
