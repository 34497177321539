import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'

import axios from '@/lib/API/client'
import fetcher from '@/lib/API/fetcher'
import useSWRV from 'swrv'
import { mutate } from 'swrv'
import { format } from '@/lib/helpers/time'
import tailwind from '@/lib/tailwind'

export default () => {
    const route = useRoute()

    const currentDate = ref(new Date())
    const horseId = ref('')
    const year = ref(currentDate.value.getFullYear())
    const month = ref(currentDate.value.getMonth() + 1) //month start a 0
    const byInterval = ref('daily') //month start a 0

    /** BEGIN HORSETRACK SECTION */
    const healthZones = [
        { start: 0, end: 25, color: tailwind.theme.colors.state.red }, // Danger zone
        { start: 25, end: 60, color: tailwind.theme.colors.state.orange }, // Warning zone
        { start: 60, end: 100, color: tailwind.theme.colors.state.green }, // Healthy zone
    ]

    // Utility function to determine health status and color based on health zones
    function getHealthStatus(healthLevel, healthZones) {
        const zone = healthZones.find((z) => healthLevel < z.end) // Find the matching health zone
        if (healthLevel === null || !zone) return { status: null, color: null }
        const status = zone === healthZones[0] ? 'danger' : zone === healthZones[1] ? 'warning' : 'good'
        return { status, color: zone.color } // Return the status and the color of the matched zone
    }

    // Utility function to sort anomalies by `start_at` in descending order
    function sortByStartAt(anomalies) {
        return [...anomalies].sort((a, b) => new Date(b.start_at) - new Date(a.start_at))
    }

    const enrichedHorsetrack = computed(() => {
        const anomalies = horsetrack.value || []
        const sortedAnomalies = sortByStartAt(anomalies) // Sort anomalies by `start_at` descending

        return sortedAnomalies.map((anomaly, index, array) => {
            const confidenceLevel = parseFloat(anomaly.anomaly_confidence_level_percent)

            // Calculate health level (100% - confidence level)
            const healthLevel = confidenceLevel ? 100 - Math.round(confidenceLevel) : null

            // Use the utility function to determine health status and color
            const { status: healthStatus, color } = getHealthStatus(healthLevel, healthZones)

            let trend = null

            if (index < array.length - 1) {
                const next = array[index + 1]

                if (
                    parseFloat(next?.anomaly_confidence_level_percent) > 0 ||
                    next?.anomaly_confidence_level_percent === 0 ||
                    next?.anomaly_confidence_level_percent === '0'
                ) {
                    const nextValue = parseFloat(next.anomaly_confidence_level_percent)
                    const nextHealthLevel = Math.round(100 - nextValue)
                    if (nextHealthLevel === 0 || healthLevel === 0) {
                        trend = healthLevel - nextHealthLevel
                    } else {
                        trend = Math.round((healthLevel * 100) / nextHealthLevel - 100)
                    }
                }
            }

            return {
                ...anomaly,
                healthLevel,
                healthStatus,
                color, // Add the corresponding color
                trend,
            }
        })
    })

    // Main method to calculate anomalies and averages for a given period
    function calculateAnomaliesAndAverage(from, to) {
        const anomalies = enrichedHorsetrack.value || []

        // Filter anomalies based on the date range
        const filteredAnomalies = anomalies.filter((anomaly) => {
            if (!anomaly.start_at) return false // Check that the anomaly has a valid date
            const anomalyDate = new Date(anomaly.start_at).getTime() // UTC
            return anomalyDate >= from.getTime() && anomalyDate < to.getTime()
        })

        // Enrich anomalies with health status and color
        const enrichedAnomalies = filteredAnomalies.map((anomaly) => {
            const confidenceLevel = parseFloat(anomaly.anomaly_confidence_level_percent)
            const healthLevel = confidenceLevel ? 100 - Math.round(confidenceLevel) : null
            const { status: healthStatus, color } = getHealthStatus(healthLevel, healthZones)

            return {
                ...anomaly,
                healthLevel,
                healthStatus,
                color,
            }
        })

        // Calculate the average for the filtered anomalies
        const totalConfidence = enrichedAnomalies.reduce((sum, anomaly) => {
            return sum + (parseFloat(anomaly.anomaly_confidence_level_percent) || 0)
        }, 0)

        const averageConfidence = enrichedAnomalies.length > 0 ? totalConfidence / enrichedAnomalies.length : null
        const healthLevel = averageConfidence ? 100 - Math.round(averageConfidence) : null
        const { status: healthStatus, color } = getHealthStatus(healthLevel, healthZones)

        const average = {
            averageConfidence: averageConfidence?.toFixed(2) || null,
            healthLevel,
            healthStatus,
            color,
            totalAnomalies: enrichedAnomalies.length,
        }

        return { anomalies: enrichedAnomalies, average }
    }
    /** END HORSETRACK SECTION */

    const { data: horses, error } = useSWRV('/user/horses?all=1', fetcher)

    const { data: horse, isValidating: isValidatingHorse } = useSWRV(
        () => horseId.value && `/horses/${horseId.value}`,
        () => fetchHorse(horseId.value),
        { revalidateOnFocus: false }
    )

    const { data: horseStatistics } = useSWRV(
        () =>
            horseId.value &&
            `/horses/${horseId.value}/reporting?month=${month.value}&year=${year.value}&by=${byInterval.value}`,
        fetcher
    )

    const { data: horsetrack, isValidating: isValidatingHorsetrack } = useSWRV(
        () => horseId.value && `horses/${horseId.value}/horsetrack?at=${currentDate.value?.toISOString()}`,
        fetcher,
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        }
    )

    /**
     *
     * mutate* methods
     *
     **/

    const mutateHorses = (data) => {
        mutate('/user/horses?all=1', data ? data : fetcher('/user/horses?all=1'))
    }

    const mutateHorse = (horseId, data) => {
        mutate(`/horses/${horseId}`, data ? setHorse(data) : fetchHorse(horseId))
    }

    /**
     *
     * fetch* methods
     *
     **/
    const fetchHorse = (horseId) => {
        return fetcher(`/horses/${horseId}`, setHorse)
    }

    /**
     *
     * internal methods
     *
     **/
    const setHorse = (newHorse = {}) => {
        Object.keys(newHorse).forEach((key) => {
            switch (key) {
                case 'born_at':
                    newHorse[key] = newHorse[key] ? format(newHorse[key].replace('Z', ''), 'YYYY-MM-DD') : ''
                    break
                default:
                    newHorse[key] = newHorse[key] ?? ''
            }
        })
        return newHorse
    }

    /**
     *
     * API methods
     *
     **/
    const updateHorse = (horseId, data) => {
        return axios.put(`/horses/${horseId}`, data).then((response) => {
            mutateHorse(horseId, response.data)
            mutateHorses()
            return response.data
        })
    }

    const removeHorse = (horseId) => {
        return axios.delete(`/horses/${horseId}`).then((response) => {
            mutateHorse(horseId, {})
            mutateHorses()
            return response.data
        })
    }

    const updateHorseAvatar = (horseId, file) => {
        let formData = new FormData()
        formData.append('avatar', file)
        return axios
            .post(`/horses/${horseId}/avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                mutateHorse(horseId, response.data)
                mutateHorses()
                return response.data
            })
    }

    const createHorse = (data) => {
        return axios.post('/horses', data).then((response) => {
            mutateHorse(response.data.id, response.data)
            mutateHorses()
            return response.data
        })
    }

    const shareHorse = (horseId, email, job = null) => {
        return axios.post(`/horses/${horseId}/sharing/invite`, { email, job }).then((response) => {
            return response.data
        })
    }

    const unshareHorse = (horseId, userId) => {
        return axios.delete(`/horses/${horseId}/sharing/${userId}`).then((response) => {
            console.log('response', response)
            // mutateHorse(response.data.id, response.data)
            mutateHorses()
            return response.data
        })
    }

    const transferOwnershipHorse = (horseId, newOwnerId) => {
        return axios.put(`/horses/${horseId}/owners`, { newOwnerId }).then((response) => {
            mutateHorses()
            return response.data
        })
    }

    watch(
        [() => route.params.horse],
        async ([newHorseId], [oldHorseId]) => {
            horseId.value = newHorseId ?? oldHorseId ?? ''
        },
        { immediate: true }
    )

    watch(
        currentDate,
        async () => {
            year.value = currentDate.value.getFullYear()
            month.value = currentDate.value.getMonth() + 1 //month start a 0
        },
        { immediate: true }
    )

    return {
        //data
        healthZones,
        horses,
        error,
        horse,
        isValidatingHorse,
        horsetrack,
        isValidatingHorsetrack,
        horseStatistics,
        calculateAnomaliesAndAverage,
        horseId,
        year,
        month,
        currentDate,
        byInterval,
        //methods
        getHealthStatus,
        setHorse,
        updateHorse,
        removeHorse,
        updateHorseAvatar,
        createHorse,
        shareHorse,
        unshareHorse,
        mutateHorse,
        transferOwnershipHorse,
    }
}
