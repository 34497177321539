import { ref, computed, watch, inject } from 'vue'
import { Capacitor } from '@capacitor/core'
import { KeepAwake } from '@capacitor-community/keep-awake'
import useSensor from './sensor'
import useFile from './file'
import Bootloader from '@/lib/memory/Bootloader'
import Memory from '@/lib/memory'
import Storage from '@/lib/memory/Storage'
import * as Sentry from '@sentry/vue'

import { BleClient, numbersToDataView, dataViewToNumbers, dataViewToText } from '@capacitor-community/bluetooth-le'
import { concatenateUint8Arrays, decodeRecord, SESSION_PAGE_SIZE, PAGE_SIZE } from './common'
export default ({ triggerEvent, language, heartrate }) => {
    const sensor = useSensor()
    // ************************************************
    // ****************  HEARTRATE events ****************
    // ***********************************************
    watch(
        () => heartrate.properties.heartrateTimestamp,
        () => {
            if (!sensor.isStopped.value && !sensor.isPaused.value && sensor.liveDuration.value > 0) {
                // console.log('callback new hr', heartrate.properties.heartrate, heartrate.properties.heartrateTimestamp)
                sensor.liveSession.heartrateStream.push([sensor.liveDuration.value, heartrate.properties.heartrate])
            }
        }
    )

    // watch(
    //     () => heartrate.properties.ecgTimestamp,
    //     () => {
    //         if (!sensor.isStopped.value && !sensor.isPaused.value && sensor.liveDuration.value > 0) {
    //             // console.log('callback new ecg', heartrate.properties.ecg.length, heartrate.properties.ecgTimestamp)
    //             sensor.liveSession.ecgStream.push([sensor.liveDuration.value, heartrate.properties.ecg])
    //             //keep a maximum of n values to prevent memory crash https://github.com/Alogo-Analysis/mobile-app/issues/429
    //             sensor.liveSession.ecgStream.splice(0, sensor.liveSession.ecgStream.length - 7800) //7800 = 130 records/second (130Hz) * 60 seconds * 1 minute
    //         }
    //     }
    // )

    // ************************************************
    // ****************  CUSTOM properties ****************
    // ***********************************************
    // CONSTANTS
    const UUIDs = {
        // Alogo Mode Service
        AMS: '00001111-fa70-efde-1523-785fef13d123',
        AMS_MODE: '00001101-fa70-efde-1523-785fef13d123', // Descriptors => Alogo_Mode_Service [0,1]
        // Alogo Session Service
        ASS: '00002222-fa70-efde-1523-785fef13d123',
        ASS_STATUS: '00002201-fa70-efde-1523-785fef13d123', // Descriptors => Alogo_Session_Service [0,1]
        ASS_DATA: '00002202-fa70-efde-1523-785fef13d123', // Descriptors => [0,1]
        // Alogo Sensor Service
        ASN: '00003333-fa70-efde-1523-785fef13d123',
        ASN_UUID: '00003301-fa70-efde-1523-785fef13d123', // Descriptors => Alogo_Sensor_Service
        ASN_CTIM: '00003302-fa70-efde-1523-785fef13d123', // Descriptors =>
        ASN_UMEM: '00003303-fa70-efde-1523-785fef13d123', // Descriptors =>
        ASN_FWVER: '00003304-fa70-efde-1523-785fef13d123', // Descriptors =>
        ASN_TIDLE: '00003305-fa70-efde-1523-785fef13d123', // Descriptors =>
        ASN_STATUS: '00003306-fa70-efde-1523-785fef13d123', // Descriptors => [0,1]
        // Alogo Calibration Service
        ACS: '00004444-fa70-efde-1523-785fef13d123',
        ACS_STATUS: '00004401-fa70-efde-1523-785fef13d123', // Descriptors => Alogo_Calibration_Service [0,0]
        ACS_DATA: '00004402-fa70-efde-1523-785fef13d123', // Descriptors => [0,0]
        // Battery Service
        BAS: '0000180f-0000-1000-8000-00805f9b34fb',
        BAS_BATTERY_LEVEL: '00002a19-0000-1000-8000-00805f9b34fb', // Descriptors => [1,0]
        // Alogo Updater Service
        AUS: '00006666-fa70-efde-1523-785fef13d123',
        AUS_STATUS: '00006601-fa70-efde-1523-785fef13d123', // Descriptors => Alogo_Updater_Service [1,0]
        AUS_FWDATA: '00006602-fa70-efde-1523-785fef13d123', // Descriptors =>
    }
    const ASS_STATUS_LIST = 129
    const ASS_STATUS_SESSION = 130
    const SERVICES = [UUIDs['AMS'], UUIDs['ASS'], UUIDs['ASN'], UUIDs['ACS'], UUIDs['BAS'], UUIDs['AUS']]
    let device = undefined
    let dataMode = undefined //ability to determine if user ask sessions list or session's data
    let loop = 0 //ability to loop over the data receive
    let previousChunk = undefined //store not read yet data (incomplete)
    let sessionSelectedIndex = undefined //the selected session during a sync
    let readDataTimer = undefined
    let currentPage = undefined
    let numberOfPages = undefined
    let lastPage = undefined
    let startTime = undefined
    let lastConnectedSensorId = undefined
    const requestBleDeviceOptions = { namePrefix: 'Alogo', optionalServices: SERVICES }
    let bootloader = new Bootloader()
    // const sessionsStorage = Storage.make().dataDirectory()
    // const sessionsStoragePath = 'sessions'
    // ************************************************
    // **************** OVERRIDED properties ****************
    // ***********************************************
    sensor.language = language
    sensor.driver = 'bluetooth'
    // ************************************************
    // **************** CUSTOM constructor ****************
    // ***********************************************
    sensor.hasWebAPIAvailble = 'bluetooth' in navigator

    Memory.get('lastConnectedSensorId').then((deviceId) => {
        lastConnectedSensorId = deviceId
    })
    // ************************************************
    // **************** OVERRIDED methods ****************
    // ***********************************************
    /**
     * Open a connection to a sensor
     */
    sensor.connect = async () => {
        return new Promise(async (resolve, _) => {
            // catch if not supported
            try {
                await BleClient.stopLEScan()
            } catch (error) {
                // console.log('### debug', error)
            }
            BleClient.requestDevice(requestBleDeviceOptions)
                .then(connectDevice)
                .catch(async (error) => {
                    // The user didn't select a device.
                    console.log('connectSensor', error)
                    await sensor.disconnect()
                })
                .finally(() => {
                    resolve(sensor.isConnected.value)
                })
        })
    }
    /**
     * Close a connection to a sensor
     */
    sensor.disconnect = async (forgetDevice = false) => {
        sensor.isBusy.value = true
        try {
            setDataMode(undefined)
            if (forgetDevice) {
                Memory.remove('lastConnectedSensorId')
                lastConnectedSensorId = undefined
            }
            if (device?.deviceId) {
                const p1 = stopNotifications()
                const p2 = BleClient.stopEnabledNotifications()
                const p3 = BleClient.disconnect(device.deviceId)
                await Promise.allSettled([p1, p2, p3])
            }
        } catch (error) {
            console.log('disconnect error', error)
        } finally {
            sensor.resetSensor()
            device = undefined
            sensor.isConnected.value = false
            sensor.isBusy.value = false
        }
    }
    /**
     * Read the uuid
     */
    sensor.getUUID = async (force = false) => {
        const mode = await BleClient.read(device.deviceId, UUIDs['ASN'], UUIDs['ASN_UUID'])
        sensor.properties.uuid = dataViewToText(mode)
    }
    /**
     * Set to power on mode
     */
    sensor.powerOn = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([3]))
    }
    /**
     * Shutdown the sensor
     */
    sensor.shutdown = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([0]))
    }
    /**
     * Set to OTA mode / DFU
     */
    sensor.setOTAMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([1]))
    }
    /**
     * Set to init mode
     */
    sensor.setInitMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([2]))
    }
    /**
     * Set to ADV mode
     */
    sensor.setADVMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([4]))
    }
    /**
     * Set to navigation mode
     */
    sensor.setNavigationMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([5]))
    }
    /**
     * Set to memory mode
     */
    sensor.setMemoryMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([6]))
    }
    /**
     * Set to calibration mode
     */
    sensor.setCalibrationMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([7]))
    }
    /**
     * Reset sensor settings
     */
    sensor.resetSettings = async (force = false) => {
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([30]))
    }
    /**
     * Set to lost mode
     */
    sensor.setLostMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([8]))
    }
    /**
     * Set to STMBL mode
     */
    sensor.setSTMBLMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([9]))
    }
    /**
     * Set to STMNBL mode
     */
    sensor.setSTMNBLMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'], numbersToDataView([10]))
    }
    /**
     * Set to updateFirmware
     */
    sensor.updateFirmware = async () => {
        console.log('updateFirmware')
        await sensor.setSTMBLMode()
        await sensor.sleep(1000)
        await BleClient.write(device.deviceId, UUIDs['AUS'], UUIDs['AUS_STATUS'], numbersToDataView([1])) //ERASE
        await sensor.sleep(1000)
        /**
         * Loads new firmware on the device
         */
        if (!sensor.isConnected.value) {
            alert("Error:\r\nConnect to your 'Alogo Move' device.")
            return
        }

        if (sensor.properties.amsMode !== 9) {
            alert("Error:\r\nPut Your 'Alogo Move' device in STMBL Mode.")
            return
        }

        bootloader.start('Alogo').then(async (result) => {
            if (result == true) {
                await BleClient.write(device.deviceId, UUIDs['AUS'], UUIDs['AUS_FWDATA'], bootloader.continue_())

                console.log(
                    'bluetooth/SET_AUS_BOOTLOADER_TEXT',
                    bootloader.byteCnt.toString() + ' / ' + bootloader.arrayBuffer_Firmware.byteLength.toString()
                )
            }
        })
    }

    /**
     * Set to updateFirmwareNRF
     */
    sensor.updateFirmwareNRF = async () => {
        console.log('updateFirmwareNRF')
        await sensor.setOTAMode()
    }
    /**
     * Get the current mode
     */
    sensor.getMode = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        const mode = await BleClient.read(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'])
        // console.log('mode', mode)
        return mode
    }
    /**
     * Remove the sensor memory
     */
    sensor.eraseMemory = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        // try {
        //     sessionsStorage.rmdir(sessionsStoragePath)
        // } catch (errrmdir) {}
        await sensor.setMemoryMode(force)
        await sensor.sleep(200)
        await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([128]))
        await sensor.sleep(2000)
        await sensor.setNavigationMode(force)
        const keysList = await Memory.keys()
        for (let index = 0; index < keysList.length; index++) {
            const key = keysList[index]
            if (/^D[0-9]+/.test(key)) {
                const filePropertites = await Memory.get(key)
                if (!filePropertites?.uuid || filePropertites?.uuid === sensor.properties.uuid) {
                    await Memory.remove(key)
                }
            }
        }
        sensor.resetSensorFiles()
    }
    /**
     * IDLE
     */
    sensor.idle = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([0]))
    }
    /**
     * RECORD
     */
    sensor.record = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([1]))
    }
    /**
     * PAUSE
     */
    sensor.pause = async () => {
        if (sensor.properties.assStatus !== 1) {
            console.log('FAILED TO PAUSE')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([2]))
    }
    /**
     * RESUME
     */
    sensor.resume = async () => {
        if (sensor.properties.assStatus !== 2) {
            console.log('FAILED TO RESUME')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([3]))
    }
    /**
     * STOP
     */
    sensor.stop = async () => {
        if (sensor.properties.assStatus !== 1 && sensor.properties.assStatus !== 2) {
            console.log('FAILED TO STOP')
            return false
        }
        return await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([0]))
    }
    /**
     * GET_LIST
     */
    sensor.getSessionsList = async (force = false) => {
        // Technically we could fetch session list during the recording
        // or any other process except when fetching data
        // so this is why this is comment bellow
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        // if (dataMode !== undefined) {
        //     triggerEvent('onProcessLocked')
        //     return false
        // }
        sensor.isBusy.value = true
        setDataMode(ASS_STATUS_LIST)
        sensor.resetSensorFiles()
        await BleClient.write(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'], numbersToDataView([ASS_STATUS_LIST]))
        while (sensor.isBusy.value) {
            // console.log('wait 1s')
            await sensor.sleep(1000)
        }
        // sensor.isBusy.value = false // not necessary because already done in another function
    }
    /**
     * GET_LIGHT_SESSION
     */
    sensor.syncSelectedSessions = async (force = false) => {
        if (!waitingForInstructions(force)) {
            triggerEvent('onProcessLocked')
            return false
        }
        try {
            sensor.isBusy.value = true
            await KeepAwake.keepAwake()
            await cleanupSync()
            await sensor.setMemoryMode(true)
            await sensor.sleep(200)
            for (let fileIndex = 0; fileIndex < sensor.files.length; fileIndex++) {
                sensor.isBusy.value = true // to force isBusy true on all loop
                sessionSelectedIndex = fileIndex
                if (!sensor.files[fileIndex].properties?.selected === true) {
                    continue
                }
                if (!sensor.files[fileIndex].pagesSynced) {
                    sensor.files[fileIndex].pagesSynced = {}
                }

                /**
                 * BEGIN Save file content to memory
                 */
                // const filename = sensor.files[sessionSelectedIndex].properties.filename
                // try {
                //     const content = await sessionsStorage.readFile(sessionsStoragePath, filename)
                //     try {
                //         const parsedContent = JSON.parse(content?.data)
                //         if (filename && parsedContent?.properties?.filename === filename) {
                //             console.log('Same filename', filename)
                //             const file = await useFile(parsedContent.properties)
                //             sensor.files[sessionSelectedIndex] = file
                //             sensor.files[sessionSelectedIndex].heartrateStream = parsedContent.heartrateStream
                //             sensor.files[sessionSelectedIndex].pagesSynced = parsedContent.pagesSynced
                //         } else {
                //             console.log('Failed to read session data, not the same filename')
                //         }
                //     } catch (errRead) {
                //         console.log('Failed to parse session data', errRead, filename)
                //         try {
                //             // const fullPath = sessionsStorage.getFilePath(sessionsStoragePath, filename)
                //             // await sessionsStorage.deleteFile(fullPath)
                //             console.log('File deleted from storage')
                //         } catch (errDelete) {
                //             console.log('Failed to delete session data', errDelete, filename)
                //         }
                //     }
                // } catch (errStorage) {
                //     console.log('Failed to retrieve session data', errStorage)
                // }
                /**
                 * END Save file content to memory
                 */

                //do not fetch again the data from the sensor if already downloaded
                if (sensor.files[sessionSelectedIndex].properties?.syncedPercent === 100) {
                    let fileUploaded = sensor.files[sessionSelectedIndex] //to keep a reference to the uploaded file
                    uploadSessionFile(fileUploaded)
                    // await sensor.files[sessionSelectedIndex]
                    //     .upload()
                    //     .then(() => {})
                    //     .catch((error) => {
                    //         fileUploaded.properties.uploadedPercent = 0
                    //         triggerEvent('onUploadFailed', fileUploaded)
                    //         console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                    //         Sentry.captureMessage(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                    //     })
                    //     .finally(() => {})
                    sessionSelectedIndex = undefined
                    continue //go to the next file to sync
                }

                setDataMode(ASS_STATUS_SESSION)
                const index = sensor.files[fileIndex].properties.index
                await fetchSessionByIndex(index)

                console.log('OK', index)
                while (sensor.isBusy.value) {
                    await sensor.sleep(1000)
                }
            }
            console.log('FINISH')
        } catch (error) {
            console.log('syncSelectedSessions error', error)
        }
        await KeepAwake.allowSleep()
        await sensor.setNavigationMode(true)
        sensor.isBusy.value = false
    }

    sensor.askPermissions = async (openSettingsIfFailed = false) => {
        try {
            await BleClient.initialize()
        } catch (error) {
            console.log('askPermissions error', error)
            if (openSettingsIfFailed) {
                // open the app settings
                await BleClient.openAppSettings()
                await sensor.sleep(1000)
            }
        }
        //here we try catch because if it is not initialize it fails
        try {
            await BleClient.stopEnabledNotifications() //prevent launching twice
            await BleClient.startEnabledNotifications((enabled) => setIsEnabled(enabled))
            //should be done after the startEnabledNotifications
            let enabled = await BleClient.isEnabled()
            if (Capacitor.getPlatform() === 'android' && !enabled) {
                await BleClient.enable() //enable the bluetooth
                await sensor.sleep(1000)
                enabled = await BleClient.isEnabled()
            }
            await setIsEnabled(enabled, openSettingsIfFailed)
            return enabled
        } catch (error) {}
        return false
    }

    // ************************************************
    // **************** CUSTOM methods ****************
    // ***********************************************

    const uploadSessionFile = async (fileUploaded, remainingAttempt = 3) => {
        await fileUploaded
            .upload()
            .then(async () => {
                try {
                    const fullPath = sessionsStorage.getFilePath(
                        sessionsStoragePath,
                        fileUploaded?.properties?.filename
                    )
                    await sessionsStorage.deleteFile(fullPath)
                } catch (errDelete) {}
            })
            .catch((error) => {
                if (remainingAttempt > 0) {
                    setTimeout(() => {
                        console.log(
                            `Failed to upload the session file, going to try again; remaining attempt ${remainingAttempt}`
                        )
                        remainingAttempt--
                        uploadSessionFile(fileUploaded, remainingAttempt)
                    }, 3000)
                } else {
                    fileUploaded.properties.uploadedPercent = 0
                    triggerEvent('onUploadFailed', fileUploaded)
                    console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                    try {
                        Sentry.captureMessage(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                    } catch (err) {}
                }
            })
            .finally(() => {})
    }

    const connectDevice = async (newDevice) => {
        if (sensor.isBusy.value === true) return
        sensor.isBusy.value = true
        device = newDevice
        if (lastConnectedSensorId !== device.deviceId) {
            lastConnectedSensorId = device.deviceId
            await Memory.set('lastConnectedSensorId', device.deviceId)
        }
        sensor.properties.name = device.name
        try {
            setDataMode(undefined)
            //https:github.com/capacitor-community/bluetooth-le/blob/main/README.md#troubleshooting
            await BleClient.disconnect(device.deviceId)
            await BleClient.connect(device.deviceId, (oldDeviceId) => {
                console.log(`device ${oldDeviceId} disconnected`)
                //we do that before disconnect() because we already know the device is not link to the sensor
                device = undefined
                sensor.disconnect()
            })

            const assStatus = await BleClient.read(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'])
            sensor.properties.assStatus = assStatus.getUint8(0)
            const ausStatus = await BleClient.read(device.deviceId, UUIDs['AUS'], UUIDs['AUS_STATUS'])
            sensor.properties.ausStatus = ausStatus.getUint8(0)
            const amsMode = await BleClient.read(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'])
            sensor.properties.amsMode = amsMode.getUint8(0)
            const cumulativeTime = await BleClient.read(device.deviceId, UUIDs['ASN'], UUIDs['ASN_CTIM'])
            sensor.properties.cumulativeTime = dataViewToText(cumulativeTime)
            const turnOffTime = await BleClient.read(device.deviceId, UUIDs['ASN'], UUIDs['ASN_TIDLE'])
            sensor.properties.turnOffTime = turnOffTime.getUint8(0)
            const asnFwver = await BleClient.read(device.deviceId, UUIDs['ASN'], UUIDs['ASN_FWVER'])
            const firmwares = dataViewToText(asnFwver).split('-')
            sensor.properties.firmware = firmwares[1]
            sensor.properties.firmwareNRF = firmwares[0]

            //start all notifications listeners
            await startNotifications()

            // dirty fix to remove the pending sync chunks
            if (sensor.properties.assStatus === 130) {
                await cleanupSync()
            }
            //force the navigation mode
            if (waitingForInstructions(true)) {
                await sensor.setNavigationMode(true)
            }

            // fetch all services available
            // const info = await BleClient.getServices(device.deviceId)
            // console.log('info', info)

            await sensor.getUUID(true)

            // we ask here + in notification because some time the notification is not trigger
            const battery = await BleClient.read(device.deviceId, UUIDs['BAS'], UUIDs['BAS_BATTERY_LEVEL'])
            sensor.properties.battery = battery.getUint8(0)

            const ASN_STATUS = await BleClient.read(device.deviceId, UUIDs['ASN'], UUIDs['ASN_STATUS'])
            parseAsnStatus(ASN_STATUS)

            sensor.isConnected.value = true

            await sensor.sleep(500)
        } catch (error) {
            console.log('connectDevice error', error)
            sensor.disconnect()
        }
        sensor.isBusy.value = false
    }

    const setIsEnabled = async (enabled, openSettingsIfFailed = false) => {
        // console.log('### setIsEnabled', enabled)
        if (Capacitor.getPlatform() === 'android') {
            const locationEnabled = await BleClient.isLocationEnabled()
            if (!locationEnabled && openSettingsIfFailed) {
                await BleClient.openLocationSettings()
                await sensor.sleep(500)
            }
            if (await BleClient.isLocationEnabled()) {
                sensor.isEnabled.value = true
            } else {
                sensor.isEnabled.value = false
            }
        } else {
            sensor.isEnabled.value = enabled
        }
    }

    const restartLEScan = async () => {
        // catch if not supported
        try {
            await BleClient.stopLEScan()
            sensor.isNearby.value = false
            for (let i = sensor.nearbyDevices.length - 1; i >= 0; i--) {
                if (Date.now() - sensor.nearbyDevices[i].lastSeen > 10000) {
                    sensor.nearbyDevices.splice(i, 1) //to not break the reactivity
                }
            }
            if (sensor.isEnabled.value) {
                //! keep in mind here this is not working on browser
                await BleClient.requestLEScan({}, (newDevice) => {
                    // console.log('newDevice', newDevice)
                    if (newDevice?.device?.name && newDevice.device.name.startsWith('AlogoMove')) {
                        sensor.isNearby.value = true
                        const existingDevice = sensor.nearbyDevices.find(
                            (d) => d.deviceId === newDevice.device.deviceId
                        )
                        if (existingDevice) {
                            existingDevice.lastSeen = Date.now()
                        } else {
                            sensor.nearbyDevices.push({
                                ...newDevice.device,
                                lastSeen: Date.now(),
                            })
                        }
                    }
                    if (newDevice?.device?.deviceId === lastConnectedSensorId) {
                        connectDevice(newDevice.device).then(() => {
                            if (sensor.isConnected.value) {
                                sensor.getSessionsList()
                            }
                        })
                    } else if (newDevice?.device?.deviceId === heartrate.lastConnectedId.value) {
                        heartrate.connectDevice(newDevice.device).then(() => {
                            console.log('### Connected HEARTRATE')
                        })
                    }
                })
            }
        } catch (error) {
            // console.log('### debug', error)

            try {
                if (error.message.includes('initialized')) {
                    console.log('Try to force to initialize BLE')
                    await BleClient.initialize()
                }
            } catch (err) {}
        }
    }
    setInterval(() => {
        restartLEScan()
    }, 10000)

    const fetchSessionByIndex = async (index) => {
        const recordIndexBytes = new Uint8Array(new Uint32Array([index]).buffer)
        const characteristicValue = new Uint8Array(5)
        characteristicValue[0] = ASS_STATUS_SESSION
        characteristicValue[1] = recordIndexBytes[0]
        characteristicValue[2] = recordIndexBytes[1]
        characteristicValue[3] = recordIndexBytes[2]
        characteristicValue[4] = recordIndexBytes[3]

        await BleClient.write(
            device.deviceId,
            UUIDs['ASS'],
            UUIDs['ASS_STATUS'],
            numbersToDataView(characteristicValue)
        )
    }

    const cleanupSync = async () => {
        setDataMode(undefined)
        await sensor.setMemoryMode(true)
        await sensor.sleep(500)
        //here we choose 4278190080 huge number who will never be [0,0,0,255]
        await fetchSessionByIndex(4278190080)
        await sensor.sleep(500)
    }

    const stopDataTimer = () => {
        if (readDataTimer) {
            clearTimeout(readDataTimer)
            readDataTimer = undefined
        }
    }

    const startDataTimer = (timeout = 5000) => {
        sensor.isBusy.value = true
        stopDataTimer()
        readDataTimer = setTimeout(() => {
            sensor.isBusy.value = false
            switch (dataMode) {
                case ASS_STATUS_LIST:
                    triggerEvent('onGetSessionsListFailed')
                    break
                case ASS_STATUS_SESSION:
                    triggerEvent('onGetSessionFailed')
                    break
            }
            setDataMode(undefined) //to ignore rest
        }, timeout)
    }

    const setDataMode = (newModeId) => {
        dataMode = newModeId
        loop = 0 // reset the number of loop received from the sensor
        previousChunk = undefined // reset the previous chunk
    }

    const waitingForInstructions = (force = false) => {
        if (sensor.isBusy.value && !force) {
            return false
        }
        return (
            (sensor.properties.amsMode === 5 || sensor.properties.amsMode === 6 || sensor.properties.amsMode === 7) &&
            sensor.properties.assStatus === 0
        )
    }

    const parseAsnStatus = (ASN_STATUS) => {
        // const week_number = ASN_STATUS.getUint16(4, true)
        // sensor.properties.gpsDate = getDateString(week_number, integer_time_of_the_week, 1)
        sensor.properties.gpsFix = ASN_STATUS.getUint8(6)
        sensor.properties.gpsFlags = ASN_STATUS.getUint8(7)
        sensor.properties.gpsSatellites = ASN_STATUS.getUint8(8)
        sensor.properties.gpsAccuracy = ASN_STATUS.getUint32(9, true)
        sensor.properties.gpsDistance = ASN_STATUS.getUint16(13, true)
        sensor.properties.gpsSpeed = ASN_STATUS.getUint16(15, true)
        sensor.properties.fusionStatus = ASN_STATUS.getUint8(17)
        sensor.properties.memory = ASN_STATUS.getUint8(18)
        sensor.properties.stmMode = ASN_STATUS.getUint8(19)
        // console.log('fusionStatus', sensor.properties.fusionStatus, 'gpsFix', sensor.properties.gpsFix)
        // // fusionStatus => 0 gpsFix => 0 => 1
        // // fusionStatus => 0 gpsFix => 3 => 1
        // // fusionStatus => 2 gpsFix => 3 => 2
        // // fusionStatus => 0 gpsFix => 2 => 1
        // // fusionStatus => 0 gpsFix => 3 => 1
        // // fusionStatus => 2 gpsFix => 3 => 2
        // // fusionStatus => 34 gpsFix => 3 => 3
    }

    const stopNotifications = async () => {
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['AMS'], UUIDs['AMS_MODE'])
        } catch (err) {
            console.log('stopNotifications AMS error', err)
        }
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['ASS'], UUIDs['ASS_STATUS'])
        } catch (err) {
            console.log('stopNotifications ASS error', err)
        }
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['ASS'], UUIDs['ASS_DATA'])
        } catch (err) {
            console.log('stopNotifications ASS error', err)
        }
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['ASN'], UUIDs['ASN_STATUS'])
        } catch (err) {
            console.log('stopNotifications ASN error', err)
        }
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['BAS'], UUIDs['BAS_BATTERY_LEVEL'])
        } catch (err) {
            console.log('stopNotifications BAS error', err)
        }
        try {
            await BleClient.stopNotifications(device.deviceId, UUIDs['AUS'], UUIDs['AUS_STATUS'])
        } catch (err) {
            console.log('stopNotifications AUS error', err)
        }
    }

    const startNotifications = async () => {
        // Alogo Mode Service
        const amsModePromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['AMS'],
            UUIDs['AMS_MODE'],
            (value) => {
                // console.log('NTF AMS_MODE', value.getUint8(0))
                // value="0">0x00: OFF
                // value="1">0x01: OTA
                // value="2">0x02: INIT
                // value="3">0x03: ON
                // value="4">0x04: ADV
                // value="5">0x05: CON
                // value="6">0x06: MEM
                // value="7">0x07: CALIB
                // value="8">0x08: LOST
                // value="9">0x09: STMBL
                // value="10">0x0A: STMNBL
                sensor.properties.amsMode = value.getUint8(0)
            }
        )

        // Alogo Session Service
        const assStatusPromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['ASS'],
            UUIDs['ASS_STATUS'],
            (value) => {
                //todo ass status comme recording etc. faire un mapping verbose du status
                // console.log('NTF ASS_STATUS', value)
                // console.log('NTF ASS_STATUS', value.getUint8(0))
                // value="0">0x00: IDLE
                // value="1">0x01: RECORD
                // value="2">0x02: PAUSE
                // value="3">0x03: RESUME
                // value="128">0x80: ERASE
                // value="129">0x81: GET_LIST
                // value="130">0x82: GET_SESSION
                // value="131">0x83: GET_LIGHT_SESSION => NOOOO DOES NOT EXIST
                sensor.properties.assStatus = value.getUint8(0)
            }
        )

        // // Alogo Session Data
        const assDataPromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['ASS'],
            UUIDs['ASS_DATA'],
            async (value) => {
                // console.log('NTF ASS_DATA', value)
                // console.log('NTF ASS_DATA', dataMode, loop)
                // console.log('NTF ASS_DATA', dataViewToNumbers(value))
                if (dataMode === undefined) {
                    console.log('chunk skipped')
                    return
                }
                try {
                    stopDataTimer()
                    startDataTimer()

                    switch (dataMode) {
                        case ASS_STATUS_LIST:
                            // console.log('value', value, loop)
                            let initialOffset = 0
                            if (loop === 0) {
                                //we have the number of session in the 4 first bytes
                                initialOffset = 4
                                sensor.properties.filesCount = value.getUint8(0)
                            } else {
                                value = new DataView(
                                    concatenateUint8Arrays(previousChunk, dataViewToNumbers(value)).buffer
                                )
                            }

                            const numberOfSessionsInValue = parseInt(
                                (value.byteLength - initialOffset) / SESSION_PAGE_SIZE
                            )
                            //save the remainder
                            const valueNumbers = dataViewToNumbers(value)
                            previousChunk = valueNumbers.slice(
                                numberOfSessionsInValue * SESSION_PAGE_SIZE + initialOffset,
                                value.byteLength
                            )

                            //there is no session on the sensor
                            if (numberOfSessionsInValue === 0) {
                                setDataMode(undefined) //to skip next chunks
                                sensor.isBusy.value = false
                            } else {
                                //at least one session
                                for (let i = 0; i < numberOfSessionsInValue; i++) {
                                    const offset = i * SESSION_PAGE_SIZE + initialOffset
                                    const record = decodeRecord(value, offset)
                                    stopDataTimer()

                                    useFile({
                                        ...record,
                                        uuid: sensor.properties.uuid,
                                        language: sensor.language.value,
                                    })
                                        .then((file) => {
                                            // console.log(file.properties.size, sensor.fileSizeLimit)
                                            if (file.properties.size > sensor.fileSizeLimit) {
                                                sensor.files.push(file)
                                            } else {
                                                sensor.properties.filesCount--
                                                console.log(
                                                    'Skipped file too small, https://github.com/Alogo-Analysis/mobile-app/issues/454'
                                                )
                                            }
                                        })
                                        .finally(() => {
                                            if (sensor.properties.filesCount === sensor.files.length) {
                                                setDataMode(undefined) //to skip next chunks
                                                sensor.isBusy.value = false
                                            }
                                        })
                                }
                            }
                            break
                        case ASS_STATUS_SESSION:
                            // console.log('NTF 130 ASS_DATA', dataViewToNumbers(value).length)
                            if (loop === 0) {
                                startTime = Date.now()
                                numberOfPages = sensor.files[sessionSelectedIndex].properties.numberOfPages
                                lastPage =
                                    sensor.files[sessionSelectedIndex].properties.startPage +
                                    sensor.files[sessionSelectedIndex].properties.numberOfPages -
                                    1
                                console.log(`Start reading ${numberOfPages} pages`)
                                currentPage = sensor.files[sessionSelectedIndex].properties.startPage
                                previousChunk = [] //reset the chunks buffer
                            }

                            previousChunk = concatenateUint8Arrays(previousChunk, dataViewToNumbers(value))

                            if (previousChunk.length >= PAGE_SIZE) {
                                sensor.files[sessionSelectedIndex].pagesSynced[currentPage] = previousChunk.slice(
                                    0,
                                    PAGE_SIZE
                                )
                                previousChunk = previousChunk.slice(PAGE_SIZE, previousChunk.length)
                                currentPage++
                                sensor.files[sessionSelectedIndex].properties.syncedPercent = Math.ceil(
                                    ((numberOfPages - (lastPage - currentPage)) * 100) / numberOfPages
                                )
                            }
                            // console.log(
                            //     currentPage + ' / ' + lastPage,
                            //     previousChunk.length,
                            //     dataViewToNumbers(value).length
                            // )
                            if (lastPage === currentPage) {
                                console.log('Sync executed in', Math.round((Date.now() - startTime) / 1000) + 's')

                                stopDataTimer()

                                /**
                                 * BEGIN save file to memory in case of failed upload
                                 */
                                // try {
                                //     await sessionsStorage
                                //         .directoryExists(sessionsStoragePath)
                                //         .then(() => console.log('sessions folder already created'))
                                //         .catch(() => {
                                //             console.log('sessions folder not exist so create it')
                                //             sessionsStorage.mkdir(sessionsStoragePath)
                                //         })
                                //         .finally(() => {
                                //             sessionsStorage.writeFile(
                                //                 sessionsStoragePath,
                                //                 sensor.files[sessionSelectedIndex]?.properties?.filename,
                                //                 JSON.stringify(sensor.files[sessionSelectedIndex])
                                //             )
                                //             console.log('Session data saved to memory')
                                //         })
                                // } catch (errStorage) {
                                //     console.log('Failed to store session data', errStorage)
                                // }
                                /**
                                 * END save file to memory in case of failed upload
                                 */

                                let fileUploaded = sensor.files[sessionSelectedIndex] //to keep a reference to the uploaded file
                                uploadSessionFile(fileUploaded)
                                // sensor.files[sessionSelectedIndex]
                                //     .upload()
                                //     .then(() => {})
                                //     .catch((error) => {
                                //         fileUploaded.properties.uploadedPercent = 0
                                //         triggerEvent('onUploadFailed', fileUploaded)
                                //         console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                                //         Sentry.captureMessage(JSON.stringify(error, Object.getOwnPropertyNames(error)))
                                //     })
                                //     .finally(() => {})
                                sessionSelectedIndex = undefined
                                setDataMode(undefined) //to skip next chunks

                                //dirty fix to reset the sync of the session to start a new one
                                sensor.setNavigationMode(true).finally(async () => {
                                    await sensor.sleep(500)
                                    sensor.setMemoryMode(true).finally(async () => {
                                        await sensor.sleep(500)
                                        sensor.isBusy.value = false
                                    })
                                })
                            }
                            break
                        default:
                            console.log('NTF ASS_DATA', value)
                    }
                    loop++
                } catch (err) {
                    console.log('assDataPromise error', err)
                }
            }
        )

        // SENSOR INFO
        const asnStatusPromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['ASN'],
            UUIDs['ASN_STATUS'],
            (ASN_STATUS) => {
                parseAsnStatus(ASN_STATUS)
            }
        )

        // Battery
        const basBatteryLevelPromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['BAS'],
            UUIDs['BAS_BATTERY_LEVEL'],
            (battery) => {
                sensor.properties.battery = battery.getUint8(0)
            }
        )

        // Alogo Updater Service
        const ausStatusPromise = BleClient.startNotifications(
            device.deviceId,
            UUIDs['AUS'],
            UUIDs['AUS_STATUS'],
            (value) => {
                // console.log('NTF AUS_STATUS', value.getUint8(0))
                sensor.properties.ausStatus = value.getUint8(0)
                // value="0">0x00: IDLE
                // value="1">0x01: ERASE
                // value="2">0x02: PROGRAM
            }
        )

        const promises = [
            amsModePromise,
            assStatusPromise,
            assDataPromise,
            asnStatusPromise,
            basBatteryLevelPromise,
            ausStatusPromise,
        ]
        await Promise.all(promises)
    }

    const setDisplayStrings = (displayString) => {
        BleClient.setDisplayStrings(displayString)
    }

    return { ...sensor, connectDevice, setDisplayStrings, UUIDs }
}
